/* Admin styles
*/

.fa{
	color: $text-color;
	font-size: 18px;
}

.no-delete{
	color: #c0c0c0 !important;
}

td.actions{
	width: 10px;
	white-space: nowrap;

	form{
		display: inline;

		button{
			border: 0;
			background: none;
			display: inline-block;
			padding: 0;
		}
	}
}

.modal.loading .modal-content:before {
    content: 'Loading...';
    text-align: center;
    line-height: 155px;
    font-size: 20px;
    background: rgba(0, 0, 0, .8);
    position: absolute;
    top: 55px;
    bottom: 0;
    left: 0;
    right: 0;
    color: #EEE;
    z-index: 1000;
}


/*
 * Callouts
 *
 * Not quite alerts, but custom and helpful notes for folks reading the docs.
 * Requires a base and modifier class.
 */

/* Common styles for all types */
.bs-callout {
  padding: 20px;
  margin: 20px 0;
  border: 1px solid #eee;
  border-left-width: 5px;
  border-radius: 3px;

	a{
		text-decoration: underline;
	}
}

.bs-callout h4 {
  margin-top: 0;
  margin-bottom: 5px;
}
.bs-callout p:last-child {
  margin-bottom: 0;
}
.bs-callout code {
  border-radius: 3px;
}

/* Tighten up space between multiple callouts */
.bs-callout + .bs-callout {
  margin-top: -5px;
}

/* Variations */
.bs-callout-danger {
  border-left-color: #ce4844;
}
.bs-callout-danger h4 {
  color: #ce4844;
}
.bs-callout-warning {
  border-left-color: #aa6708;
}
.bs-callout-warning h4 {
  color: #aa6708;
}
.bs-callout-info {
  border-left-color: #1b809e;
}
.bs-callout-info h4 {
  color: #1b809e;
}
.bs-callout-success {
  border-left-color: #C6D880;
	background-color: #E6EFC2;
}
.bs-callout-success h4 {
  color: $black;
}
.bs-callout-notice {
  border-left-color: #ecbe6a;
	background-color: #f1d9ae;
}
.bs-callout-notice h4 {
  color: $black;
}
