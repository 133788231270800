// Commonly used elements

.loading {
    border: 16px solid #f3f3f3; /* Light grey */
    border-top: 16px solid #0090b1; /* Blue */
    border-radius: 50%;
    width: 120px;
    height: 120px;
    animation: spin 2s linear infinite;
		margin: auto;
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}

.ui-autocomplete {
	max-height: 280px;
	overflow-y: auto;
	/* prevent horizontal scrollbar */
	overflow-x: hidden;
}

.use-capitals{
	text-transform: uppercase;
}

.arrow-down {
	margin-left: 1px;
	top: -1px;
	position: relative;
	display: inline-block;
	width: 0;
	height: 0;
	border-left: 7px solid transparent;
	border-right: 7px solid transparent;
	border-top: 7px solid $black;
}

table.table-condensed{
	thead tr th, 
	tr td,
	tbody tr th,
	tbody tr td,
	tfoot tr th,
	tfoot tr td{
		padding: 4px;
	}
}