// Buttons

.btn{
	@include transition(all 0.1s ease)
	font-family: $primary-font;
	text-decoration: none !important;
}

.btn-condensed{
	padding: 2px 5px;
}

.btn-primary{
	background: $blue;
	color: $white;
	border: 0;

	&:hover{
		background: $blue-hover;
		color: $white;
	}
}

.btn-disabled{
	.glyphicon{
		color: #cccccc;
	}

	&:hover{
		background: $white;
		border: 1px solid #cccccc;
	}
}

.btn-blue{
	background: $blue;
	color: $white;

	&:hover{
		background: $blue-hover;
		color: $white;
	}
}

.btn-facebook {
	color: $white;
	background-color: #3b5998;
	border-color: rgba(0,0,0,0.2);
}

.btn-linkedin {
	color: #fff;
	background-color: #007bb6;
	border-color: rgba(0,0,0,0.2);
}

.btn-twitter {
	color: $white;
	background-color: #55acee;
	border-color: rgba(0,0,0,0.2);
}

.btn-social{
	.fa{
		color: $white;
	}
}

.btn-social>:first-child {
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    width: 32px;
    line-height: 34px;
    font-size: 1.6em;
    text-align: center;
    border-right: 1px solid rgba(0,0,0,0.2);
}

.btn-social {
    position: relative;
    padding-left: 44px;
    text-align: left;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.btn-enquire{
	color: $white;

	span{
		color: $white;
		margin-right: 2px;
	}
}