/* Fixes */
body.admin{
	.flash-message{
		margin-top: 20px;
	}
}

.glyphicon{
	color: $black;
}
