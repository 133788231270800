/* Welcome page Styles
*/

body.home{
	font-family: $primary-font;

	div.flash-message{
		position: absolute;
    width: 400px;
    top: 120px;
    z-index: 10;
    left: 50%;
    margin-left: -200px;
		background: rgba(0,0,0,0.65);
		color: $white;
		text-align: center;
		@include box-shadow(0 8px 6px -6px #3d3d3d);

		.alert{
			margin-bottom: 0;
		}

		@media screen and (max-width: 767px){
			left: 0;
			width: 92%;
			margin: 0 4%;
			background: rgba(0,0,0,1);
		}

		.alert-success,
		.alert-danger{
			border-radius: 0;
			background: none;
			border: 0;
			color: $white;

			a.close{
				float: none;
				position: absolute;
				top: 0px;
				right: 10px;
				background: none;
				font-size: 30px;
				color: $white;
				opacity: 1;
				font-weight: 100;
				text-shadow: none;
			}
		}
	}

	header{
		nav{
			margin-bottom: 0;
			ul.navbar-nav{
				margin-top: 23px;
				margin-right: 0;
				li{
					a{
						padding: 3px 10px;
						border-radius: 3px;
						text-transform: none;
						font-weight: 500;
						font-size: 14px;
						width: 110px;
						text-align: center;
						transition-duration: 0.3s;
					}
					&.blue_btn{
						margin-right: 20px;
						a{
							background-color: #008db4;
							color: #ffffff;
							border: 1px solid #008db4;
							&:hover{
								background-color: #ffffff;
								color: #008db4;
							}
							@media screen and (max-width: 767px){
								background-color: #ffffff;
								color: #008db4;
								border: none;
								width: 100%;
							}
						}
						@media screen and (max-width: 767px){
							margin-right: 0;
						}
					}
					&.white_btn{
						a{
							color: #008db4;
							background-color: #ffffff;
							border: 1px solid #008db4;
							&:hover{
								background-color: #008db4;
								color: #ffffff;
							}
							@media screen and (max-width: 767px){
								border: none;
								width: 100%;
							}
						}
					}
					@media screen and (max-width: 767px){
						display: block;
						margin: 0;
					}
				}
				@media screen and (max-width: 767px){
					margin: 0;
				}
			}
		}
	}

	#page-content{
		.row{
			display: none;
		}
	}

	.hero{
		position: relative;
		img{
			max-width: 100%;
			visibility: hidden;
		}
		.hero_text_wrapper{
			margin: auto;
			width: 900px;
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
			.hero_text{
				max-width: 475px;
				h1, p{
					color: #ffffff;
					font-weight: normal;
				}
				h1{
					font-size: 40px;
					@media screen and (max-width: 900px){
						font-size: 36px;
					}
					@media screen and (max-width: 480px){
						font-size: 30px;
					}
					@media screen and (max-width: 340px){
						font-size: 27px;
					}
				}
				p{
					font-size: 25px;
					line-height: 32px;
					margin-bottom: 0;
					@media screen and (max-width: 900px){
						font-size: 22px;
						line-height: 29px;
					}
					@media screen and (max-width: 480px){
						font-size: 18px;
						line-height: 22px;
					}
					@media screen and (max-width: 340px){
						font-size: 16px;
						line-height: 20px;
					}
				}
				@media screen and (max-width: 1000px){
					padding: 50px;
					box-sizing: content-box;
				}
				@media screen and (max-width: 480px){
					padding: 15px;
				}
			}
			@media screen and (max-width: 1000px){
				width: 100%;
			}
		}
		@media screen and (max-width: 767px){
			background-attachment: initial !important;
		}
	}
	.text_full_width{
		background-color: #ffffff;
		padding: 50px;
		p{
			background-color: #ffffff;
			max-width: 900px;
			text-align: center;
			margin: auto;
			color: #000000;
			font-size: 17px;
			a{
				color: #000000;
				text-decoration: underline;
				&:hover{
					color: #216a94;
				}
			}
			&:first-of-type{
				margin-bottom: 1em;
			}
		}
		@media screen and (max-width: 480px){
			padding: 15px;
		}
	}
	.blue_bg{
		background-image: url('/img/welcome/link-bg.png');
		background-color: #008db4;
	}
	.contact_block{
		background-color: #464646;
	}
	.blue_bg, .contact_block, .footer{
		font-size: 0;
		.bg_container{
			max-width: 900px;
			margin: auto;
			padding: 50px;
			overflow: auto;
			box-sizing: content-box;
			.bg_left, .bg_right{
				width: 50%;
				display: inline-block;
				float: left;
				@media screen and (max-width: 767px){
					width: 100%;
					text-align: center;
				}
			}
			.bg_left{
				.blue_text{
					h2, p{
						color: #ffffff;
						margin: 0;
					}
					h2{
						font-size: 21px;
						margin-bottom: 15px;
					}
					p{
						font-size: 17px;
						font-weight: 100;
						line-height: 21px;
					}
				}
			}
			.contact_text, .contact_form{
				h3, p{
					color: #ffffff;
					text-transform: uppercase;
				}
				h3{
					font-size: 16px;
					margin: 0;
					font-weight: 500;
					text-align: justify;
					@media screen and (max-width: 767px){
						text-align: center;
					}
				}
				p{
					font-size: 14px;
					margin: 0;
					font-weight: 100;
					&:first-of-type{
						margin-bottom: 20px;
					}
				}
			}
			.bg_right{
				.blue_screen{
					width: 355px;
					float: right; 
					position: relative;
					img{
						position: relative;
						z-index: 1500;
						@media screen and (max-width: 900px){
							max-width: 100%;
						}
					}
					.screen_slider{
						width: 327px;
						overflow: hidden;
						position: absolute;
						top: 15px;
						left: 14px;
						.screen_slide{
							width: 327px !important;
							height: 183px;
							display: inline-block;
							@media screen and (max-width: 900px){
								width: 276px !important;
								height: 158px;
							}
							@media screen and (max-width: 480px){
								width: 258px !important;
								height: 145px;
							}
						}
						.slick-dots{
							list-style-type: none;
							margin-left: 0;
							text-align: center;
							margin-top: 85px;
							padding-left: 0;
							li{
								display: inline-block;
								margin: 0 5px;
								button{
									background-color: white;
									font-size: 0 !important;
									width: 8px;
									height: 8px;
									padding: 0;
									border: 0;
									border-radius: 50%;
									cursor: pointer;
									transform: scale(1);
									transition-duration: 0.3s;
									&:focus{
										outline: 0;
									}
								}
								&.slick-active{
									button{
										transform: scale(1.5);
									}
								}
							}
							@media screen and (max-width: 480px){
								margin-top: 72px;
							}
						}
						@media screen and (max-width: 900px){
							width: 276px;
							top: 12px;
							left: 13px;
						}
						@media screen and (max-width: 480px){
							width: 258px;
						}
					}
					@media screen and (max-width: 900px){
						width: 300px;
					}
					@media screen and (max-width: 767px){
						float: none;
						margin: auto;
					}
					@media screen and (max-width: 480px){
						width: 280px;
						margin-bottom: 28px;
					}
				}
				.contact_form{
					form{
						margin-top: 15px;
						textarea{
							width: 100%;
							height: 80px;
							margin-top: 15px;
							background-color: #636363;
							border: 1px solid #6d6d6d;
							color: #ffffff;
							font-size: 14px;
							padding: 2px 5px;
						}
						input{
							font-size: 14px;
							background-color: #636363;
							border: 1px solid #6d6d6d;
							color: #ffffff;
							padding: 0 5px;
							&.input_name, &.input_email{
								width: 47%;
							}
							&.input_name{
								margin-right: 6%;
							}
							&::-webkit-input-placeholder { /* Chrome */
							  color: #ffffff;
							}
							&:-ms-input-placeholder { /* IE 10+ */
							  color: #ffffff;
							}
							&::-moz-placeholder { /* Firefox 19+ */
							  color: #ffffff;
							  opacity: 1;
							}
							&:-moz-placeholder { /* Firefox 4 - 18 */
							  color: #ffffff;
							  opacity: 1;
							}
						}
						.btn_send{
							font-size: 16px;
							text-transform: uppercase;
							background-color: #ffffff;
							color: #464646;
							border: 0;
							padding: 0 10px;
							font-weight: bold;
							float: right;
							margin-top: 15px;
						}
					}
				}
				@media screen and (max-width: 767px){
					margin-top: 20px;
				}
			}
			@media screen and (max-width: 480px){
				padding: 15px;
			}
		}
	}
	.footer{
		background-color: #2e292a;
		.bg_container{
			.footer_text{
				float: right;
				max-width: 70%;
				text-align: right;
				p, a{
					color: white;
					font-size: 14px;
					text-align: right;
					margin-bottom: 0;
					@media screen and (max-width: 767px){
						text-align: center;
					}
				}
				a{
					&:first-of-type{
						&:after{
							content: "|";
							margin-left: 5px;
							margin-right: 5px;
							display: inline-block;
							@media screen and (max-width: 767px){
								content: "";
								margin: 0;
							}
						}
					}
					@media screen and (max-width: 767px){
						display: block;
					}
				}
				@media screen and (max-width: 767px){
					max-width: 100%;
					float: none;
					text-align: center;
					margin-top: 20px; 
				}
			}
			@media screen and (max-width: 767px){
				text-align: center;
			}
		}
	}
	footer{
		display: none;
	}
}