// Body
$body-bg: #f5f5f5;

// Colours
$white: #ffffff;
$blue: #0090b1;
$blue-hover: lighten($blue, 9%);
$black: #333333;
$dark-grey: #4d4d4d;
$link-blue: $blue;
$off-white: #f5f5f5;


// Borders
$laravel-border-color: darken($body-bg, 10%);
$list-group-border: $laravel-border-color;
$navbar-default-border: $laravel-border-color;
$panel-default-border: $laravel-border-color;
$panel-inner-border: $laravel-border-color;

// Brands
$brand-primary: #3097D1;
$brand-info: #8eb4cb;
$brand-success: #2ab27b;
$brand-warning: #cbb956;
$brand-danger: #bf5329;

// Typography
$icon-font-path: "~bootstrap-sass/assets/fonts/bootstrap/";
$primary-font: "Raleway", sans-serif;
$font-size-base: 14px;
$line-height-base: 1.6;
$text-color: #636b6f;

// Navbar
$navbar-default-bg: #fff;

// Buttons
$btn-default-color: $text-color;

// Inputs
$input-border: lighten($text-color, 40%);
$input-border-focus: lighten($brand-primary, 25%);
$input-color-placeholder: lighten($text-color, 30%);

// Panels
$panel-default-heading-bg: #fff;


// Pagination
$pagination-hover-color: $white;
$pagination-active-bg: $blue;
$pagination-active-border: $blue;


// Viewports
$screen-xxxs-max: 360px;
$screen-xxs-max: 479px;
$screen-xs-max: 767px;
$screen-sm-max: 768px;
$screen-md-max: 992px;
$screen-lg-max: 1200px;

// Padding
$padding-standard: 40px;
$padding-xs: 10px;


// Bootstrap
$grid-gutter-width:         30px;

// Large screen / wide desktop
$container-large-desktop:      (1080px + $grid-gutter-width);
