/* Layout Styles
*/

header{
	width: 100%;
	background: $white;

	ul.nav{
		a{
			color: #636b6f;
			font-size: 12px;
			font-weight: 600;
			letter-spacing: .1rem;
			text-decoration: none;
			text-transform: uppercase;
		}
	}

	div.container{
		position: relative;

		a.logo{
			text-decoration: none;
			height: auto;
			display: inline-block;
			float: left;
			padding: 10px 0;
			img{
				@media screen and (max-width: $screen-xxs-max){
					max-width: 100%;
				}
			}

			@media screen and (max-width: $screen-sm-max) {
				margin-left: 15px;
			}
			@media screen and (max-width: $screen-xxs-max){
				width: 200px;
			}
		}

		p.username-role{
			margin-top: 5px;
			text-align: right;
			text-transform: uppercase;

			img.client-logo{
				margin-right: 10px;
			}

			a{
				color: #636b6f;
				font-size: 12px;
				font-weight: 600;
				letter-spacing: .1rem;
				text-decoration: none;
				text-transform: uppercase;			
			}

			span{
				font-size: 10px;
				text-transform: none;
				margin-right: 10px;
			}
		}
	}
}

footer{
	background: $white;
	padding: 10px 0 5px 0;
	text-align: center;
	border-top: 1px solid #dcdbdb;

	p{
		color: #91918f;
	}

}