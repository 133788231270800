/* Base Styles
*/

body{
	overflow-x: hidden;
}

h1,h2,h3,h4,h5{
	font-family: $primary-font;
	color: $black;
}

h1{
	color: $dark-grey;
	font-weight: 700;
	font-size: 29px;
	margin-top: 0;

	&.margin-top{
		margin-top: 20px;
	}

	@media screen and (max-width: $screen-sm-max) {
		font-size: 25px;
	}
}

a{
	color: $link-blue;

	&.underline{
		text-decoration: underline;
	}
}

div.page-content{
	background: $off-white;
}

.breadcrumb{
	margin-top: 15px;
	background: $white;

	.disabled{
		a{
			color: #cccccc;
		}
	}
}
